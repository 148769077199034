import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Other</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Server Deployments`}</h3>
    <ul>
      <li parentName="ul">{`Workflow Step actions have been moved all the way to the right to prevent accidental misclicking when trying to view step execution details
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.05.08%252FWorkflowStepPlayButton.png&isNativeBrowsing=false",
          "alt": "Workflow Step actions"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`Clicking on the server action execution status will bring up the dialog with the action execution log output
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.05.08%252FExecuteActionsLog.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`White spaces at the beginning and at the end of a deployment profile paramater values are now trimmed. White spaces in the middle of the value will stay intact.`}</li>
    </ul>
    <h3>{`Other`}</h3>
    <ul>
      <li parentName="ul">{`Link to Provision Portal online documentation
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.05.08%252FHelpLink.png&isNativeBrowsing=false",
          "alt": "Help Link"
        }}></img></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      